import { DateRange } from '@shared/components/chitin/molecules/daterange-picker/date-range';
import { NumberRange } from '@shared/components/chitin/molecules/range-input/range-input.component';
import { Filter } from '@shared/components/data-view/data-view-types';
import moment from 'moment';

export function updateFilterWithDisplayValue(filter: Filter.AppliedDataViewFilter) {
  filter.icon = undefined;
  filter.iconText = undefined;
  filter.iconTextPostfix = undefined;
  filter.displayValue = undefined;
  if (!(filter.value == undefined || filter.value === '')) {
    switch (filter.type) {
      case 'beginsWith': {
        filter.icon = 'fa-solid fa-equals';
        filter.iconText = '...';
        filter.iconTextPostfix = true;
        break;
      }
      case 'endsWith': {
        filter.icon = 'fa-solid fa-equals';
        filter.iconText = '...';
        break;
      }
      case 'contains': {
        filter.icon = 'fa-solid fa-tilde';
        break;
      }
      case 'ncontains': {
        filter.icon = 'fa-solid fa-object-exclude';
        break;
      }
      case 'date': {
        const valueDate = moment(filter.value);
        filter.displayValue = valueDate.format('MM/DD/YYYY');
        filter.icon = 'fa-solid fa-calendar';
        break;
      }
      case 'dateRange':
      case 'datetimeRange': {
        const valueDateRange = filter.value as DateRange;
        const preset = valueDateRange.preset;
        if (preset) {
          filter.displayValue = valueDateRange.presetDisplayValue;
          filter.icon = 'fa-solid fa-calendar';
        } else {
          const from = moment(valueDateRange.from).format('MM/DD/YYYY');
          const to = moment(valueDateRange.to).format('MM/DD/YYYY');
          if (from === to) {
            filter.displayValue = from;
            filter.icon = 'fa-solid fa-calendar';
            break;
          }
          filter.icon = 'fa-solid fa-calendar-clock';
          filter.displayValue = `${from} - ${to}`;
        }
        if (filter.timezone) {
          filter.displayValue += ` (${filter.timezone})`;
        }

        break;
      }
      case 'equal': {
        filter.icon = 'fa-solid fa-equals';
        break;
      }
      case 'nequal': {
        filter.icon = 'fa-solid fa-not-equal';
        break;
      }
      case 'range': {
        const from = (filter.value as NumberRange).from;
        const to = (filter.value as NumberRange).to;
        if (!from && !to) {
          filter.displayValue = '';
          break;
        }
        if (!from) {
          filter.icon = 'fa-solid fa-less-than-equal';
          filter.displayValue = `${to}`;
          break;
        }
        if (!to) {
          filter.icon = 'fa-solid fa-greater-than-equal';
          filter.displayValue = `${from}`;
          break;
        }
        filter.iconText = 'IN RANGE';
        filter.displayValue = `${from} - ${to}`;
        break;
      }
      case 'lt': {
        filter.icon = 'fa-solid fa-less-than';
        break;
      }
      case 'gt': {
        filter.icon = 'fa-solid fa-greater-than';
        break;
      }
      case 'in': {
        if (Array.isArray(filter.value)) {
          if ((filter.options ?? []).length > 0 && filter.value.length === 1) {
            filter.icon = 'fa-solid fa-equals';
            filter.displayValue = filter.options!.find(option => option.value === (filter.value as string[])[0])?.name ?? '';
          } else {
            filter.iconText = 'IN';
            filter.displayValue = `[${filter.value.length} options]`;
          }
        } else {
          filter.icon = 'fa-solid fa-equals';
          filter.displayValue = filter.options!.find(option => option.value === filter.value)?.name ?? '';
        }
        break;
      }
      case 'nin': {
        filter.iconText = 'NOT IN';
        filter.displayValue = Array.isArray(filter.value)
          ? `[${filter.value.length} option${filter.value.length === 1 ? '' : 's'}]`
          : undefined;
        break;
      }
      case 'isOneOf': {
        const typedValue = filter.value as string[];
        filter.iconText = 'IS ONE OF';
        filter.displayValue = typedValue.length > 0 ? `[${typedValue.length} option${typedValue.length === 1 ? '' : 's'}]` : '';
        break;
      }
      case 'nisOneOf': {
        const typedValue = filter.value as string[];
        filter.iconText = "ISN'T ONE OF";
        filter.displayValue = typedValue.length > 0 ? `[${typedValue.length} option${typedValue.length === 1 ? '' : 's'}]` : '';
        break;
      }
      case 'exists': {
        filter.iconText = 'EXISTS';
        filter.displayValue = '';
        break;
      }
      case 'nexists': {
        filter.iconText = "DOESN'T EXIST";
        filter.displayValue = '';
        break;
      }
    }
  }
}
