<div class="sliderWrapper">
  <div
    #overflowScrollWrapper
    (scroll)="onNavScrolled()"
    class="d-flex gap-3 align-items-stretch overflow-hidden sliderOverflowScrollWrapper"
  >
    <ng-content></ng-content>
  </div>
  <div class="scrollCurtain left" [ngClass]="{ visible: moveLeftBtnVisible }">
    <a class="moveBtn left fa-solid fa-circle-arrow-left" (click)="onNavClicked('left')"></a>
  </div>
  <div class="scrollCurtain right" [ngClass]="{ visible: moveRightBtnVisible }">
    <a class="moveBtn right fa-solid fa-circle-arrow-right" (click)="onNavClicked('right')"></a>
  </div>
</div>
