import gql from 'graphql-tag';

export const chainStatementFragment = gql`
  fragment ChainStatement on PatientAssistancePharmacyPaymentStatement {
    _id # Required for passing as dataSource on UI
    id
    adjustmentAmount
    adjustmentDescription
    amount
    paidAmount
    aggregatedBalanceAmount
    aggregatedPaidAmount
    aggregatedAdjustmentAmount
    paidAt
    periodNumber
    periodStart
    periodEnd
    status
    aggregatedStatus
    presumedDateOfPayment
    extraBenefitTotal
    extraBenefitPaymentId
    transmittedAt
  }
`;
