export const OCC_00 = '00';
export const OCC_01 = '01';
export const OCC_02 = '02';
export const OCC_03 = '03';
export const OCC_04 = '04';
export const OCC_05 = '05';
export const OCC_06 = '06';
export const OCC_07 = '07';
export const OCC_08 = '08';

const sharedPatientAssistanceOccTypeAsConst = [
  OCC_00,
  OCC_01,
  OCC_02,
  OCC_03,
  OCC_04,
  OCC_05,
  OCC_06,
  OCC_07,
  OCC_08,
] as const;

export const SHARED_PATIENT_ASSISTANCE_OCC_LIST = [...sharedPatientAssistanceOccTypeAsConst];

export type SharedPatientAssistanceOccType = (typeof sharedPatientAssistanceOccTypeAsConst)[number];

export const SHARED_PATIENT_ASSISTANCE_COVERED_OCC = [OCC_02, OCC_04, OCC_08];

export const SHARED_PATIENT_ASSISTANCE_NOT_COVERED_OCC = [OCC_00, OCC_01, OCC_03, OCC_05, OCC_06, OCC_07];
