import { WILDCARD } from '@libs/shared/infrastructure/constants/wildcard.constant';
import { ApiBoolean } from '@shared/models/api-boolean.type';
import { SelectListItem } from '@shared/models/select-list-item';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { isEqual as _isEqual, isString } from 'lodash';

export class Utils {
  static isNonEmptyArray(variableToTest: unknown): variableToTest is any[] {
    return !!variableToTest && variableToTest instanceof Array && variableToTest.length > 0;
  }

  static doesArrayHaveOneElement(arrayToTest: any): boolean {
    return this.isNonEmptyArray(arrayToTest) && arrayToTest.length === 1;
  }

  static isNonEmptyString(stringToTest: string): boolean {
    return stringToTest !== undefined && stringToTest !== '';
  }

  static isString(variableToTest: any): boolean {
    return Object.prototype.toString.call(variableToTest) === '[object String]';
  }

  static isArray(variableToTest: any): boolean {
    return Object.prototype.toString.call(variableToTest) === '[object Array]';
  }

  static copyObject<T>(object: T): T {
    return { ...object };
  }

  static copyArray<T>(array: T[]): T[] {
    const _copyArray: T[] = [];
    array.forEach(element => _copyArray.push(this.copyObject(element)));
    return _copyArray;
  }

  static castToBool(object: any): boolean {
    return !!object;
  }

  static sortArrayByProperty(array: any[], property: string) {
    return array.sort((n1, n2) => {
      if (n1[property] > n2[property]) {
        return 1;
      }
      if (n1[property] < n2[property]) {
        return -1;
      }
      return 0;
    });
  }

  static convertBoolToApiBool(value: boolean): ApiBoolean {
    return value ? 1 : 0;
  }

  static addSpaceBetweenWords(camelCasedWordsWithoutSpaces: string): string {
    const LOWERCASE_UPPERCASE_NO_SPACE_PATTERN = /([a-z])([A-Z])/g;
    const LETTERS_SEPARATED_BY_SPACE = '$1 $2';
    return camelCasedWordsWithoutSpaces.replace(LOWERCASE_UPPERCASE_NO_SPACE_PATTERN, LETTERS_SEPARATED_BY_SPACE);
  }

  static capitalizeString(string: string): string {
    if (!string) {
      return '';
    }

    if (string.length === 1) {
      return string.toUpperCase();
    }

    return string.charAt(0).toUpperCase() + string.substr(1);
  }

  static transformFieldNamesForSentence(words: string, capitalizeFirstLetter = false): string {
    let transformedWords = words.replace('_', ' ');
    if (capitalizeFirstLetter) {
      transformedWords = this.capitalizeString(transformedWords);
    }
    return transformedWords;
  }

  static generateNumberSequence(start: number, stop: number, step = 1): number[] {
    return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
  }

  static getModifiedStringArrayAsString(
    stringsToModify: string[],
    modifier: string,
    modType: 'prepend' | 'append',
    delimiter = ' ',
  ): string {
    if (!stringsToModify) {
      return '';
    }

    const modifiedStrings = stringsToModify.map(string => {
      return modType === 'prepend' ? `${modifier}${string}` : `${string}${modifier}`;
    });

    return modifiedStrings.join(delimiter);
  }

  static doFieldsExist(objectContainingFields: any, fieldNames: string[], trimStrings = true): Map<string, boolean> {
    if (!objectContainingFields || !fieldNames) {
      throw new Error('Object or fields do not exist for existence check');
    }

    const fieldNameExists = new Map<string, boolean>();

    fieldNames.forEach(fieldName => {
      const value = objectContainingFields[fieldName];
      if (this.isString(value)) {
        fieldNameExists.set(fieldName, this.isNonEmptyString(trimStrings ? value.trim() : value));
      } else {
        fieldNameExists.set(fieldName, this.castToBool(value));
      }
    });

    return fieldNameExists;
  }

  static deleteEmptyFields(object: any): void {
    Object.keys(object).forEach(key => {
      if (object[key] === undefined || object[key] === '') {
        delete object[key];
      }
    });
  }

  static isValidEmail(email: string): boolean {
    const EMAIL_PATTERN = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$');
    const matches = EMAIL_PATTERN.exec(email.toLowerCase());
    return Utils.castToBool(matches);
  }

  static isValidNumber(value: any): boolean {
    const rule = /^-?\d+(\.\d+)?$/;
    return rule.test(`${value}`);
  }

  static isValidBankNumber(value: any): boolean {
    const rule = /^\d+$/;
    return rule.test(`${value}`);
  }

  static isValidTaxNumber(value: any): boolean {
    const einRule = /^\d{2}-\d{7}$/;
    const itinRule = /^9\d{2}-\d{2}-\d{4}$/;
    const noDashRule = /^\d{9}$/;
    return einRule.test(`${value}`) || itinRule.test(`${value}`) || noDashRule.test(`${value}`);
  }

  static formatPhoneNumber(phone: string) {
    // normalize string and remove all unnecessary characters
    phone = phone.replace(/[^\d]/g, '');
    // re-format to (123) 456-7890
    if (phone.length === 10) {
      // reformat and return phone number
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
    return phone;
  }

  static uniqueFilter = <T>(value: T, index: number, self: T[]) => {
    return self.indexOf(value) === index;
  };

  static removeDecimals(numberString: string): string {
    if (!numberString) {
      return numberString;
    }
    const isDecimal = numberString.indexOf('.');
    return isDecimal ? numberString.split('.')[0] : numberString;
  }

  static safeRound(numberString: string): string {
    if (!numberString || +numberString === 0) {
      return numberString;
    }
    return Math.round(+numberString).toString();
  }

  static enumToArray(enumObj: Record<string, any>) {
    const StringIsNumber = (value: unknown) => isNaN(Number(value)) === false;
    return Object.keys(enumObj)
      .filter(StringIsNumber)
      .map(key => enumObj[key]);
  }

  static enumToSelectList(enumObj: Record<string, string>): SelectListItem[] {
    return Object.entries(enumObj).map(([label, value]) => ({ name: label, value }));
  }

  static getObjectKeys<T extends {}>(value: T): Array<keyof T> {
    return Object.keys(value) as unknown as Array<keyof T>;
  }

  static isEqual = _isEqual;

  static formatUSPhoneNumber(phoneNumber: string, keepPrefix = false, region = 'US'): string {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    try {
      const parsedPhone = phoneNumberUtil.parseAndKeepRawInput(phoneNumber, region);
      const formattedPhone = phoneNumberUtil.format(parsedPhone, PhoneNumberFormat.INTERNATIONAL);
      return keepPrefix ? formattedPhone : formattedPhone.substring(formattedPhone.indexOf(' ') + 1);
    } catch (error) {
      return '';
    }
  }

  static isPhoneNumberValid(phoneNumber: string, regions = ['US', 'PR']): boolean {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const validate = (input: string, region: string) => {
      const parsedPhone = phoneNumberUtil.parseAndKeepRawInput(input, region);
      return phoneNumberUtil.isValidNumberForRegion(parsedPhone, region);
    };
    try {
      const validations = regions.map(region => validate(phoneNumber, region));
      return validations.some(validation => validation);
    } catch (error) {
      console.warn('Error validating phone number', error);
      return false;
    }
  }

  static isNcpdpValid(value: unknown): boolean {
    return isString(value) && this.isDigitsOnly(value) && value.length === 7;
  }

  static isNpiValid(value: unknown): boolean {
    return isString(value) && this.isDigitsOnly(value) && value.length === 10;
  }

  static isBinValid(value: unknown): boolean {
    return isString(value) && (value === WILDCARD || (this.isDigitsOnly(value) && value.length === 6));
  }

  static isDigitsOnly(value: unknown): boolean {
    const rule = /^\d+$/;
    return isString(value) && rule.test(value);
  }

  static isZipValid(zipValue: string): boolean {
    return /^[0-9]{5}(?:-?[0-9]{4})?$/.test(zipValue);
  }

  static isLatitude(value: unknown): boolean {
    const num = parseFloat(`${value}`);
    return Math.abs(num) <= 90;
  }

  static isLongitude(value: unknown): boolean {
    const num = parseFloat(`${value}`);
    return Math.abs(num) <= 180;
  }

  static extendObject<TObject extends {}>(object: TObject, extend: Partial<TObject>): TObject {
    return Object.assign(object, extend);
  }

  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  static isNotEmpty(value: unknown): boolean {
    return value !== '' && value !== null && value !== undefined;
  }

  static extractField<T extends object, K extends keyof T>(item: T, property: K): T[K] | undefined {
    if (property in item) {
      const field = item[property];
      delete item[property];
      return field;
    }
  }

  static arrayOfNotEmpty<T>(items: unknown[]): T[] {
    return items.filter(item => this.isNotEmpty(item)) as T[];
  }

  static sortObjectKeysDeep(unorderedObject: Record<any, any> | any[]) {
    if (Array.isArray(unorderedObject)) {
      const orderedArray = unorderedObject.sort((a, b) => a - b);
      for (let i = 0; i < orderedArray.length; i += 1) {
        if (typeof orderedArray[i] === 'object' && orderedArray[i] !== null) {
          orderedArray[i] = this.sortObjectKeysDeep(orderedArray[i]);
        }
      }
      return orderedArray;
    }
    for (const [key, potentialObject] of Object.entries(unorderedObject)) {
      if (typeof potentialObject === 'object' && potentialObject !== null) {
        unorderedObject[key] = this.sortObjectKeysDeep(potentialObject);
      }
    }
    return Object.keys(unorderedObject)
      .sort()
      .reduce((obj: Record<string, unknown>, key) => {
        obj[key] = unorderedObject[key];
        return obj;
      }, {});
  }

  static keepOrder = () => 0;

  static fullName(firstName?: string, lastName?: string): string {
    return `${firstName ?? ''} ${lastName ?? ''}`.trim();
  }
}

export default Utils;
